@mixin font-face($font-family, $url, $weight: normal, $style: normal, $asset-pipeline: false) {
	@font-face {
		font-family: '#{$font-family}';
		font-weight: #{$weight};
		font-style: '#{$style}';

		src: url('#{$url}.eot');
		src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
		url('#{$url}.woff') format('woff'),
		url('#{$url}.ttf') format('truetype');

	}
}

// Function for converting a px based font-size to rem.
@function rem($size) {
	$remSize: $size / 14px;
	@return #{$remSize}rem;
}

@mixin toh($max_height) {
	@media screen and (max-height: $max_height) {
		@content;
	}
}


@function max($numbers...) {

	@return m#{a}x(#{$numbers})
}

;

@function min($numbers...) {

	@return m#{i}n(#{$numbers})
}

;


$full-width: 1200;
$work-width: 80;
$screen-height: 720;

@function vmax($pixels, $context: $full-width) {
	@return #{($pixels/$context)* 100}vmax
}
;

@function vw($pixels, $context: $full-width) {
	@return  #{($pixels/$context)* 100}vw
}

@function lh($lh, $fz) {
	@return #{($lh/$fz)}em
}
;


@mixin button($bcolor,
$url,
$x1,
$y1,
$bor,
$col) {
 background: $bcolor;
 -webkit-mask: url($url);
 mask: url($url);
 -webkit-mask-size: $x1 $y1;
 mask-size: $x1 $y1;
 border: $bor;
 color: $col;
}
