.clients {
    &-wrap {
        padding-bottom: 150px;


        @include tabletLarge {
            padding-bottom: 0;
        }

        @include mobile {
            padding-bottom: 80px;
        }
    }

    &-title {
        color: $redPrim;
        margin-bottom: 60px;

        @include tabletLarge {
            margin-bottom: 40px;
        }

        @include mobile {
            margin-bottom: 30px;
        }

    }

    &-swiper-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 116px;

        @include tabletLarge {
            gap: 85px;
        }

        @include tablet {
            gap: 20px;
        }

        @include mobile {
            justify-content: center;
        }

    }

    &-swiper {
        width: 80%;
        overflow: hidden;

        @include mobile {
            

        }

        .swiper-wrapper {
            @include mobile {
                flex-direction: column;
                justify-content: center;
            }
        }

    }
    
    &-swiper-slide {

        @include mobile {
            margin-bottom: 28px;
            display: flex;
            justify-content: center;

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    &-swiper-slide__img {

        @include mobile {
            max-width: 226.32px;
            height: 68.013px;
        }
    }

    &-swiper-button-prev {
        @include mobile {
            display: none;
        }
    }

    &-swiper-button-next {
        @include mobile {
            display: none;
        }
    }

}