$xs: 767px;
$sm: 1366px;
$md: 1920px;

@mixin media($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin mobile {
  @include media($xs) {
    @content;
  }
}

@mixin tablet {
  @include media($sm) {
    @content;
  }
}

@mixin tabletLarge {
  @include media($md) {
    @content;
  }
}



