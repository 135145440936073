@import './pages/about';
@import './pages/cart-page';
@import './pages/cart';
@import './pages/catalog-page';
@import './pages/catalog-single-page';
@import './pages/clients';
@import './pages/contact-popup';
@import './pages/contacts';
@import './pages/directions';
@import './pages/equipment-page';
@import './pages/gallery-page';
@import './pages/homepage-catalog';
@import './pages/intro';
@import './pages/news-page';
@import './pages/news';
@import './pages/product';
@import './pages/services-page';
@import './pages/single-news-page';
@import './pages/thank-page';
@import './pages/thank-you-popup';
