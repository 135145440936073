.preloader {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    pointer-events: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: $mc2;
    cursor: not-allowed;
    opacity: 1;
    padding: 0;
}

.preloader__image {
    max-width: 80vw;
    min-width: 300px;
    animation: pulse 2s ease-in-out both infinite;

    @include mobile {
        width: 180px;
        min-width: 150px;
    }
}
  

  @-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  