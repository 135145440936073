.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  &-item {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $wc;
    width: fit-content;
    &:nth-last-child(2) {
      pointer-events: none;
      cursor: none;

      a {
        padding-right: 0;
        color: #BABABA;
      }
    }
    &:first-child {
      width: fit-content;
    }
  }

  &-item__link {
    position: relative;
    text-decoration: none;
    font-family: $default-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: $wc;
    z-index: 1;
    padding-left: 20px;
    padding-right: 10px;
    transition: all ease-in-out 0.3s;
    @include mobile {
      font-size: 10px;
    } 

    &::before {
      content: '>';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--text-footer);
    }

    &::after {
      position: absolute;
      content: '';
      bottom: -3px;
      left: 20px;
      width: calc(100% - 20px);
      height: 1px;
      background: var(--text-footer);
    }
    &:hover {
      color: var(--black-100);
    }

    // &:first-child {
    //   &::before {
    //     display: none;
    //   }
    // }
  }

  &-item__link-home {
    padding-left: 0;
    width: fit-content;
      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        content: '';
        bottom: -3px;
        left: 0;
        width: calc(100% - 10px);
        height: 1px;
        background: $wc;
      }


  }
  &-list {
    display: flex;
    align-items: center;
    align-content: center;
  }

  @include to(1440px) {
    font-size: 12px;
  }
  @include to(992px) {
    font-size: 14px;
  }
  @include to(576px) {
    font-size: 10px;
  }
  &-item {
    
    &:last-child {
      display: none;
    }
  }
}
