.cart {
    &-overlay {
        padding: 150px;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        transition: ease-in visibility 0s, opacity 0.3s linear;
        background-color: rgba(0, 0, 0, 0.8);
          
        
        &.modal-open {
            
            // visibility: hidden;
            // opacity: 0;
            opacity: 1;
            visibility: visible;
            z-index: 1500;
            
            
        }
    }

    &-overlay-inner  {
        position: relative;
        height: 80%;
       

    }

    &-wrap  {
        padding: 40px;
        border: 1px solid #E3EBEF;
        background: var(--fff);
        position: relative;

    }

    &-close {
        position: absolute;
        border: none;
        background: var(--red, #DD405D);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        right: 0;
        top: 0;

        svg {
            pointer-events: none;
        }
    }

    &-list-item-delete {
        position: absolute;
        border: none;
        background: var(--red, #DD405D);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        right: 0;
        top: 0;

        svg {
            pointer-events: none;
        }

        form {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        &__input {
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            pointer-events: all;
            cursor: pointer;

        }
    }


    &-title {
        margin-bottom: 12px;
        text-align: left;


    }

    &-list {

    }

    &-list-item {
        display: flex;
        padding: 32px 20px;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        border: 1px solid var(--text-footer, #D5DDE1);
        background: var(--fff);
        position: relative;

    }

    &-list-item-img {
        width: 140px;
        height: 74px;

        img {
            height: 100%;
            object-fit: cover;
        }

    }

    &-list-item-descr-wrap{

    }

    &-list-item-descr__name {

    }

    &-list-item-descr__row {
        display: flex;


    }

    &-list-item-descr__row-code {
        display: flex;
        span {
            display: block;
            width: 2px;
        }
    }

    &-list-item-descr__row-size {

    }
    


    &-list-item-descr__row-code {

    }

    &-list-item-descr__quantity {
        border: 1px solid var(--text-footer);
        padding: 6px 10px;
        width: 101px;
    }

    &-list-item-descr__quantity-increment {
        svg {
            pointer-events: none;
            width: 12px;
            height: 12px;
        }
    }

    &-list-item-descr__quantity-decrement {

        svg {
            pointer-events: none;
            width: 12px;
            height: 12px;
        }
    }

    &-list-item-descr__quantity-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        form {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-list-item-descr__quantity-container__input-value {
        border: none;
        font-family: $default-font2;
        font-size: 14px;
        font-weight: normal;
    }

    &-list-item-descr__quantity-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &-list-item-descr__price {

    }

    &-descr {
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        border: 1px solid var(--text-footer, #D5DDE1);
        background: var(--background, #F8F8F8);
        margin-bottom: 12px;

    }

    &-descr-title {
        width: 75%;

    }

    &-descr-price {


    }

    &-descr-price-sum {

    }

    &-buttons-wrap {
        display: flex;
        justify-content: space-between;
        display: flex;
        height: 52px;
        padding: 16px 28px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;

    }

    &-buttons__back {
        width: calc(50% - 6px);
        border: 1px solid var(--text-footer, #D5DDE1);
        background: var(--background, #F8F8F8);
        display: flex;
        height: 52px;
        padding: 16px 28px;
        justify-content: center;
        align-items: center;
        gap: 12px;

        svg {
            width: 16px;
            height: 16px;
            stroke: black;
        }

    }

    &-buttons__cart {
        width: calc(50% - 6px);
        display: flex;
        height: 52px;
        padding: 16px 28px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        background: var(--red, #DD405D);

        svg {
            width: 16px;
            height: 16px;
            stroke: var(--fff);
            fill: none;

        }

    }
}