.contacts-page {
    &-wrap {

    }

    &-list-wrap {

    }

    &-list {

    }

    &-list-item  {
        display: flex;
        flex-direction: column;
        padding: 40px;
        border: 1px solid var(--text-footer);
        background: var(--fff);
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        @include tablet {
            padding: 40px 20px;
        }

    }

    &-list-item-title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.active {
            .contacts-page-list-item-title-arrow {
                svg {
                    transform: rotate(0deg);
                }
            }
        }

    }

    &-list-item-title {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

    }

    &-list-item-subtitle {
        text-transform: uppercase;
        margin-left: 5px;
    }

    &-list-item-title-arrow {
        border-radius: 100px;
        border: 1px solid var(--text-footer, #D5DDE1);
        display: flex;
        width: var(--container40);
        height: var(--container40);
        justify-content: center;
        align-items: center;


        svg {
            width: 16px;
            height: 7px;
            transform: rotate(180deg);
            transition: all ease-in-out 0.4s;
        }

    }

    &-list-item-content {
        display: none;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;

        &.active {
            display: flex;
            margin-top: 20px;

            @include tablet {
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 20px;
            }
        }
    }

    &-list-item__btn  {

        display: flex;
        height: 52px;
        padding: 16px 28px;
        justify-content: center;
        align-items: center;
        background: var(--red, #DD405D);
        border: none;

        span {
            text-transform: uppercase;
        }

        @include tablet {
            width: 100%;
            order: 1;
        }

    }

    &-list-item-content-details {
        display: flex;
        align-items: center;

        @include tablet {
            width: calc(50% - 10px);
        }

        @include mobile {
            width: 100%;
        }

    }

    &-list-item-content-details__svg-wrap {
        height: var(--container52);
        width: var(--container52);
        border-radius: 100px;
        background: var(--gray);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        svg {
            width: 24px;
            height: 24px;
            fill: none;

        }
    }

    &-list-item-content-details__link {
        display: block;

        @include mobile {

            width: calc(100% - var(--container52));
        }
        

    }

    &-list-item-content-details__link-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - var(--container52));

    }

    &-list-item-content-epicentr {
        flex-direction: column;

    }

    &-list-item-content-columns-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include tablet {
            flex-direction: column;
            gap: 40px;
        }
    }

    &-list-item-content-column {
        width: calc(33.3% - 40px);

        @include tablet {
            width: 100%;
        }

        &:first-child {

            @include tablet {
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
            
        }
    }

    &-list-item-content-column__logo  {
        margin-bottom: 20px;

        svg {
            width: 230px;
            height: 48px;
        }

    }

    &-list-item-content-column__title {
        margin-bottom: 12px;

    }

    &-list-item-content-column-profile-wrap {
        .contacts-page-list-item-content-column__title  {
            @include tablet {
                display: block;
                margin-bottom: 12px;
            }
        }
    }

    &-list-item-content-column-profile {
        display: flex;
        align-items: center;

    }

    &-list-item-content-column-profile__svg  {

        display: flex;
        width: 70px;
        height: 70px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1px solid var(--text-footer, #D5DDE1);
        background: var(--white, #FFF);

        
        svg {
            
            width: 20px;
            height: 34px;
        }
    }

    &-list-item-content-column-profile-info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

    }

    &-list-item-content-column-profile-info__name {
        font-weight: 700;
        text-transform: uppercase;
    }

    &-list-item-content-column-list {
        margin-top: 28px;

    }

    &-list-item-content-column-list-item {
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }

    }

    &-list-item-content-column-list-item__city {
        text-transform: uppercase;
        margin-bottom: 4px;
        font-weight: 700;
    }

    &-list-item-content-column-list-item-content {
        &:hover {
            .contacts-page-list-item-content-column-list-item__adress {
                border: 1px solid $redPrim;
            }
           
        }


    }
    
    &-list-item-content-column-list-item__adress {
        border-radius: 100px;
        border: 1px solid var(--text-footer);
        background: var(--fff);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px;
        width: fit-content;
        margin-bottom: 4px;
        transition: all ease-in-out 0.3s;

        
        &:last-child {
            margin-bottom: 0;
        }

        span {
            margin-right: 10px;
        }


        svg {
            width: 12px;
            height: 12px;

        }
    }

    &-list-item-content-column-text {
        margin-top: 12px;

        span {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-list-item-content-column-swiper-wrap {
        width: 100%;
    }

    &-list-item-content-column-swiper-nav-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &-list-item-content-column-swiper-nav-buttons  {
        display: flex;
        justify-content: space-between;
    }

    &-list-item-content-column-swiper-nav__next {
        margin-left: 10px;
        svg {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    &-list-swiper-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;

    }

    &-list-slide {
        position: relative;
        cursor: pointer;

        &:hover {

            .contacts-page-list-slide__img  {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);

            }
            
        }
    }

    &-list-slide__img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.4s ease-in-out;
    }



}