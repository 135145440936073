.thank-you-popup {
	&.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition: ease-in visibility 0s, opacity 0.3s linear;
		background-color: rgba(0, 0, 0, .8);
		z-index: 15000;
	  &.modal-open {
		visibility: visible;
		opacity: 1;
	  }
	}

    &-icon-wrap {
        width: 50%;
        height: 100%;
        background: $mc1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-icon {
      border: 1px solid $mc2;
      border-radius: 50%;
      height: 400px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
     

      svg {
          fill: none;
          height: 43px;
          width: 53px;
      }
  }

    &__text {
        width: 50%;
        padding: 170px 70px;
        background: $mc1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
      font-style: normal;
      font-weight: 300;
      font-size: 120px;
      line-height: 100%;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: $mc2;
    }

    &__descr {
        width: 70%;
        color: $textGreySpecial;
        margin-bottom: 20px;

    }

    &__btn {
    }
}
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	transition: ease-in visibility 0s, opacity 0.3s linear;
	background-color: rgba(0, 0, 0, .8);
	z-index: 1500;
  &.modal-open {
	visibility: visible;
	opacity: 1;
  }
}

.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  box-sizing: border-box;
  // background-image: url('../images/about/noise.jpg');
  background-size: cover;
  display: flex;
  justify-content: space-between;

}
.close-popup {
	position: absolute;
  background: var(--red);
  border-radius: 50%;
	top: 50px;
	right: 50px;
	width: 50px;
	height: 50px;
	cursor: pointer;

  @include tablet {
    right: 20px;
    top: 20px;
  }
}

.close-popup:before {
	content: '';
	background-color: $mc2;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 25px;
	left: 14px;
	transform: rotate(-45deg);
}

.close-popup:after {
	content: '';
	background-color: $mc2;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 25px;
	transform: rotate(45deg);
	left: 14px;
}