.contact-popup {
	&.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition: ease-in visibility 0s, opacity 0.3s linear;
		background-color: rgba(0, 0, 0, .8);
		z-index: 1500;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;

	  &.modal-open {
		visibility: visible;
		opacity: 1;
	  }

	}

  &-inner {
    position: relative;
    width: 663px;
    padding: 60px 150px;
    background: var(--background);



  }

  &__text-wrap {
    margin-bottom: 40px;
     
  }

  &__text {
       
  }

  &__title {
    margin-bottom: 20px;
       
  }

    &__descr {
       
    }

    &__btn {
        margin: 0 auto;
        width: 100%;
    }

    &-form {
      z-index: 10;
   
    }

    &-form__hint {
       
    }

    &-form-field {
        margin-bottom: 40px;
        margin-top: 0!important;
        height: 70px!important;
        position: relative;

    }

    &-form__input {
        border: none;
        outline: none;
        background: transparent;
        border-bottom: 1px solid #D3D3D0;
        padding: 0;
        font-family: $default-font;
        padding: 10px 0px;
        margin-bottom: 40px;
        width: 100%;
        font-size: 24px;
        font-weight: 300;
        color: #081019;
        line-height: 120%;
        letter-spacing: -0.05em;
        text-transform: none;
        margin-top: 0 !important;
        height: 70px;
    }

    &-input-message {
        position: absolute;
        color: $textGreySpecial;
        bottom: -25px;
        font-size: 12px;
        font-family: $default-font2;


    }
}
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	transition: ease-in visibility 0s, opacity 0.3s linear;
	background-color: rgba(0, 0, 0, .8);
	z-index: 1500;
  &.modal-open {
	visibility: visible;
	opacity: 1;
  }
}

#popup-form {


}

// .popup {
//     position: absolute;
//   width: 50%;
//   height: 100%;
//   right: 0;
//   top: 0;
//   padding: 0 128px;
//   box-sizing: border-box;
//   // background-image: url('../images/about/noise.jpg');
//   background-size: cover;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   @include tablet {
//     width: 80%;
//     padding: 0 80px;
//   }
//   @include mobile {
//     width: 100%;
//     padding: 68px 20px;
//   }

// }
.close-popup {
	position: absolute;
	top: 50px;
	right: 50px;
	width: 23px;
	height: 23px;
	cursor: pointer;
  @include mobile {
    right: 30px;
    top: 30px;
  }
}

.close-popup:before {
	content: '';
	background-color: $mc2;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 11px;
	left: -4px;
	transform: rotate(-45deg);
}

.close-popup:after {
	content: '';
	background-color: $mc2;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 11px;
	transform: rotate(45deg);
	left: -4px;
}