.news-page {
    &-wrap {

    }
    &-list {
        display: flex;
        flex-direction: column;
        gap: 20px;

    }
    &-item {
        display: flex;
        background: $wc;
        border: 1px solid $border;
        padding: 40px;
        gap: 40px;

        @include tabletLarge {
            gap: 20px;
        }

        @include mobile {
            flex-direction: column-reverse;
            padding: 40px 20px;
        }

        &:nth-child(2n) {
            flex-direction: row-reverse;

            @include mobile {
                flex-direction: column-reverse;
            }
        }



    }
    &-item-intro-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 60%;

        @include mobile {
            width: 100%;
        }

    }
    &-item-intro {

        @include tablet {
            margin-bottom: 40px;

        }

    }
    &-item__title {
        color: $bc;
        margin-bottom: 12px;

    }
    &-item__text {
        color: $textGrey;
        margin-bottom: 20px;

    }
    &-item__btn {
        text-transform: uppercase;

    }
    &-item-date {
        color: $textGrey;
        display: flex;
        justify-content: flex-start;
        gap: 3px;

    }
    &-item-date__day {

    }
    &-item-date__month {

    }
    &-item-date__year {

    }
    &-item__img-wrap {
        width: 39%;

        @include mobile {
            width: 100%;
        }

    }
    &-item__img {
        height: 100%;
        object-fit: cover;

    }
}