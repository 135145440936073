@import './assets/vars';  // переменные
@import './assets/media-rules';
@import './assets/smart-grid';  // mixins smartgrid.js
// libraries
@import './libs';

// assets
@import './assets/mixins';  // миксины
@import './assets/media-rules.scss';
@import './assets/fonts';  // шрифты
@import './assets/base';  // общие стили
@import './assets/animation';  // анимации
@import './assets/class'; // main style
@import './assets/interface'; // main style
@import './assets/breadcrubs'; // main style
@import './assets/main-styles';// container style


// sass dima
@import './header';
@import './footer';
@import './404';
@import './loader';
@import './menu';

@import '../../pug/components/form/form';
@import '../../pug/components/toster/toster';

//@import 'pages/contacts';
//@import 'pages/contact';
//@import 'pages/home';
@import './assets/templates'; // main style
