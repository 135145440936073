.services {
    background: $bg;
    &-wrap {

    }
    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        @include tablet {
            flex-direction: column;
        }

    }
    &-item {
        width: calc(50% - 10px);
        background: $wc;
        border: 1px solid $border;
        padding: 40px;

        @include tablet {
            width: 100%;
        }

        @include mobile {
            padding: 40px 20px;
        }


    }
    &-item__title {
        text-align: left;
        margin-bottom: 20px;

    }
    &-item__img-wrap {
        margin-bottom: 20px;
        position: relative;

    }
    &-item__img {

    }

    &-item__img-descr-wrap {
        position: absolute;
        display: flex;
        align-items: flex-end;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 32px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);

        @include mobile {
            padding: 16px;
        }

    }
    &-item__img-descr {
        color: $wc;

    }
    &-item__descr-wrap {
        margin-bottom: 20px;
        padding: 0 32px;

        @include mobile {
            padding: 0;
        }

    }
    &-item__descr-title {
        margin-bottom: 8px;

    }
    &-item__descr-list {
        padding-left: 16px;

    }
    &-item__descr-item {
        margin-bottom: 6px;
        list-style-type: disc;
        &:last-child {
            margin-bottom: 0;
        }

    }
    &-btn-wrap {

    }
    &-item__descr {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }

    }

    &-item__alert {
        padding: 12px;
        background: #F8F8F8;
        border: 1px solid #D5DDE1;
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &-item__alert-text {
        color: $textGrey;

    }

    &-item__alert-text--red {
        color: $redPrim;
        text-transform: uppercase;
        font-weight: 700;

    }

    &-item__alert-svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;

    }

}