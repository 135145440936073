.equipment {
    &-wrap {

    }

    &-list-wrap {
        display: flex;
        gap: 20px;
        margin-bottom: 100px;

        @include mobile {
            flex-direction: column;
            margin-bottom: 80px;
        }

    }
    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        width: 75%;
        

        @include tablet {
            flex-direction: column;
        }

        @include mobile {
            width: 100%;
        }

       
        
    }
    &-card {
        width: calc( 50% - 10px);
        display: flex;
        border: 1px solid $border;
        background: $wc;

        @include tablet {
            width: 100%;
        }

        @include mobile {
            flex-direction: column-reverse;

        }
        
    }
    &-card-intro {
        width: 50%;
        border-right: 1px solid $border;
         padding: 40px 30px;

         @include mobile {
            width: 100%;
         }
        
    }
    &-card__title {
        display: block;
        margin-bottom: 20px;
        
    }
    &-card-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
       
        
    }
    &-card-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;

        svg {
            width: 12px;
            height: 12px;
            stroke: var(--black);
        }
        
    }
    &-card-list-item__title {
        
    }
    &-card__img-wrap {
        width: 50%;

        @include mobile {
            width: 100%;
            border-bottom: 1px solid $border;
        }

        img {
            max-height: 300px;

            @include mobile {
                max-width: fit-content;

            }
        }
        
    }

    &-info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &-info__title{
        margin-bottom: 20px;

        @include mobile {
            margin-bottom: 40px;
        }
         
    }

    &-info {
        display: flex;
        justify-content: space-between;
        gap: 60px;

        @include tablet {
            flex-direction: column;
            gap: 20px;
        }

    }

    &-info__descr-wrap {
        width: calc(50% - 30px);

        @include tablet {
           width: 100%;
        }
    }

    &-info__descr {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

}