.row {
	@include row-flex();
}
.page__inner {
  position: relative;
	display: flex;
	flex-direction: column;
	z-index: 2;
  min-height: 100vh;
  flex: 1;
	.page__content {
		flex-grow: 1;
    overflow: hidden;
	}
	.page__footer-wrapper {
		flex-shrink: 0;
	}
}
.section-pading--top {
	padding-top: 60px;
}
.btn--res{
  border: none;
  background-color: transparent;
}

.brd{
  border: 1px solid red;
}

.underline{
  position: relative;
  display: flex;
  color: #000;
  &::after{
    position: absolute;
    bottom: -2px;
    left: 0;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #000;
    content: "";
  }
}
