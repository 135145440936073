.page404 {

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    @include tablet {
        flex-direction: column;
        justify-content: center;
    }

    &-wrap {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../images/404bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }


    &__title {
        font-style: normal;
        font-weight: 450;
        font-size: 300px;
        line-height: 71%;
        letter-spacing: -0.05em;
        text-transform: uppercase;
        color: $mc2;
        margin-bottom: 40px;
        width: 50%;
        text-align: center;

        @include tablet {
            width: 100%;
            font-size: 200px;
        }
    }

    &-intro {
        width: 50%;
        display: flex;
        flex-direction: column;

        @include tablet {
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    &__title-small {
        font-family: 'Vela Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 120px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $mc2;

        @include tablet {
            text-align: center;
            font-size: 80px;
        }
    }

    &__subtitle {
        font-family: 'Vela Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        text-transform: uppercase;
        color: #A6A299;

        @include tablet {
            text-align: center;
        }
    }

    &__link-wrap {
        display: flex;
        margin-top: 40px;

        .btn-container {
            width: 144px;
        }

        .btn-container:first-child {
            margin-right: 20px;
        }


    }


}