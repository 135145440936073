/*
* toast start
*/
.toast-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 360px;
  z-index: 31;

}

.toast {
  position: relative;
  display: flex;
  width: 100%;
  height: 103px;
  background: #FFFFFF;

  .toast-logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    width: 60px;
    height: 100%;
  }

  .toast__logo {
    width: 37px;
    height: 37px;

    svg {
      width: 100%;
      height: 100%;
      fill: rgba(255, 255, 255, 1);
    }
  }

  .toast-content-block {
    padding: 20px 50px 20px 20px;
    display: flex;
    flex-direction: column;

  }

  .toast__title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
  }

  .toast__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #8E8E8E;
  }

  .toast__colose-btn {
    position: absolute;
    padding: 0;
    display: flex;
    top: 17px;
    right: 20px;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: none;
    transition: transform 0.7s var(--in);

    @include from(1024px) {
      &:hover {
        transform: rotate(90deg) scale(1.4);
        transition: transform 0.7s var(--in)
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: rgba(255, 255, 255, 1);
    }
  }
}

[data-toast-item] {}

[data-toast-item][data-toast-status="error"]:not(#r) {
  .toast-logo-block {
    background-color: #E88E8E;
  }

  .toast__title {
    color: #E88E8E;
  }
}

[data-toast-item][data-toast-status="success"]:not(#r) {
  .toast-logo-block {
    background-color: #96C380;
  }

  .toast__title {
    color: #96C380;
  }
}

/*
  * toast end
*/